

.coding{

    background: linear-gradient(315deg, #828BE4 -222.2%, #FFCC00 -48.84%, #3562FF 57.68%, #3EC1C1 170.46%);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 40px 24px;
    gap: 20px;
}