/* import fonts from ./assets/fonts/fonts */

@tailwind base;
@tailwind components;
@tailwind utilities;

.react-datepicker__day--selected {
  background-color: #3562FF !important;
}

.react-datepicker {
  background-color: white !important;
  border: none !important;
  padding:25px !important;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1), -5px 5px 10px rgba(0, 0, 0, 0.1) !important;
}

.react-datepicker__header {
  background-color: transparent !important;
  border:none !important
}

@font-face {
  font-family: 'C-Variable';
  src: url('./../assets/fonts/Cabinet/CabinetGrotesk-Variable.woff2') format('woff2'),
       url('./../assets/fonts/Cabinet/CabinetGrotesk-Variable.woff') format('woff'),
       url('./../assets/fonts/Cabinet/CabinetGrotesk-Variable.ttf') format('truetype');
       font-weight: 100 900;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'C-Thin';
  src: url('./../assets/fonts/Cabinet/CabinetGrotesk-Thin.woff2') format('woff2'),
       url('./../assets/fonts/Cabinet/CabinetGrotesk-Thin.woff') format('woff'),
       url('./../assets/fonts/Cabinet/CabinetGrotesk-Thin.ttf') format('truetype');
       font-weight: 100;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'C-Extralight';
  src: url('./../assets/fonts/Cabinet/CabinetGrotesk-Extralight.woff2') format('woff2'),
       url('./../assets/fonts/Cabinet/CabinetGrotesk-Extralight.woff') format('woff'),
       url('./../assets/fonts/Cabinet/CabinetGrotesk-Extralight.ttf') format('truetype');
       font-weight: 200;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'C-Light';
  src: url('./../assets/fonts/Cabinet/CabinetGrotesk-Light.woff2') format('woff2'),
       url('./../assets/fonts/Cabinet/CabinetGrotesk-Light.woff') format('woff'),
       url('./../assets/fonts/Cabinet/CabinetGrotesk-Light.ttf') format('truetype');
       font-weight: 300;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'C-Regular';
  src: url('./../assets/fonts/Cabinet/CabinetGrotesk-Regular.woff2') format('woff2'),
       url('./../assets/fonts/Cabinet/CabinetGrotesk-Regular.woff') format('woff'),
       url('./../assets/fonts/Cabinet/CabinetGrotesk-Regular.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'C-Medium';
  src: url('./../assets/fonts/Cabinet/CabinetGrotesk-Medium.woff2') format('woff2'),
       url('./../assets/fonts/Cabinet/CabinetGrotesk-Medium.woff') format('woff'),
       url('./../assets/fonts/Cabinet/CabinetGrotesk-Medium.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'C-Bold';
  src: url('./../assets/fonts/Cabinet/CabinetGrotesk-Bold.woff2') format('woff2'),
       url('./../assets/fonts/Cabinet/CabinetGrotesk-Bold.woff') format('woff'),
       url('./../assets/fonts/Cabinet/CabinetGrotesk-Bold.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'C-Extrabold';
  src: url('./../assets/fonts/Cabinet/CabinetGrotesk-Extrabold.woff2') format('woff2'),
       url('./../assets/fonts/Cabinet/CabinetGrotesk-Extrabold.woff') format('woff'),
       url('./../assets/fonts/Cabinet/CabinetGrotesk-Extrabold.ttf') format('truetype');
       font-weight: 800;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'C-Black';
  src: url('./../assets/fonts/Cabinet/CabinetGrotesk-Black.woff2') format('woff2'),
       url('./../assets/fonts/Cabinet/CabinetGrotesk-Black.woff') format('woff'),
       url('./../assets/fonts/Cabinet/CabinetGrotesk-Black.ttf') format('truetype');
       font-weight: 900;
       font-display: swap;
       font-style: normal;
}




@font-face {
  font-family: 'montheavy';
  src: url('./../assets/fonts/Mont/mont-heavy-webfont.woff2') format('woff2'),
       url('./../assets/fonts/Mont/mont-heavy-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'montlight';
  src: url('./../assets/fonts/Mont/mont-light-webfont.woff2') format('woff2'),
       url('./../assets/fonts/Mont/mont-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'mont';
  src: url('./../assets/fonts/Mont/mont-regular-webfont.woff2') format('woff2'),
       url('./../assets/fonts/Mont/mont-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'montsemibold';
  src: url('./../assets/fonts/Mont/mont-semibold-webfont.woff2') format('woff2'),
       url('./../assets/fonts/Mont/mont-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'montblack';
  src: url('./../assets/fonts/Mont/mont-black-webfont.woff2') format('woff2'),
       url('./../assets/fonts/Mont/mont-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'montbold';
  src: url('./../assets/fonts/Mont/mont-bold-webfont.woff2') format('woff2'),
       url('./../assets/fonts/Mont/mont-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

:root{
  
  --font-mont: 'Mont';
  --light-bg: #FDFEFF;
  --dark-bg: #1F1F1F;
  --light-text: #FDFEFF;
  --dark-text: #1F1F1F;
  --light-grey: #F3F4F4;
  --primary-color: #F9BB11;
  --white-color: #FFFFFF;
}


.big-btn{
  background-color: var(--primary-color);
  color: var(--dark-bg);
  text-decoration: none;
  padding: 15px 35px;
  border-radius: 5px;
  font-family: 'Mont';
  font-weight: 600;
}

@layer components {
  .big-btn {
    
    background-color: theme('colors.primary_color');
    color: var('colors.dark_bg');
    text-decoration: none;
    padding: them('spacing.4') them('spacing.9');
    border-radius: them('spacing.2.5');
    font-family: theme('fontFamily.mont');
    font-weight: theme('fontWeight.bold');
  }
}

.dark input:-webkit-autofill,
.dark input:-webkit-autofill:hover,
.dark input:-webkit-autofill:focus,
.dark textarea:-webkit-autofill,
.dark textarea:-webkit-autofill:hover,
.dark textarea:-webkit-autofill:focus,
.dark select:-webkit-autofill,
.dark select:-webkit-autofill:hover,
.dark select:-webkit-autofill:focus {
  -webkit-text-fill-color: theme('colors.light_text');
  -webkit-box-shadow: 0 0 0px 1000px theme('colors.dark_grey') inset;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: theme('colors.dark_text');
  -webkit-box-shadow: 0 0 0px 1000px theme('colors.bodybg') inset;
}

.grad-loading{
  background: linear-gradient(116.27deg, rgba(221, 217, 214, 0.4) -6.38%, rgba(138, 134, 140, 0.4) 17.41%, rgba(242, 243, 243, 0.32) 54.93%, rgba(128, 124, 128, 0.4) 95.84%, rgba(166, 168, 171, 0.4) 105.42%);
}